<script setup lang="ts">
import { Quria, APIResponse, DestinyActivityHistoryResults, DestinyComponentType, BungieMembershipType, DestinyRecordComponent,
  DestinyRecordState, DestinyCharacterActivitiesComponent, DestinyCharacterProgressionComponent, GroupType, GroupsForMemberFilter,
  DestinyProfileUserInfoCard } from 'quria'
import { ref, onMounted, watch, computed } from 'vue'
import { useRoute, useRouter } from 'vue-router'
import { Splide, SplideSlide } from '@splidejs/vue-splide'
import { type Account, type AccountResult, type Activity, type Character, ErrorToast, TheFooter, useMediaQuery, formatUsername, Season } from '@wastedondestiny/destiny-library'
import SearchBar from './SearchBar.vue'
import AccountCard from './AccountCard.vue'
import MainLogo from './MainLogo.vue'
import titleDefinitions from '../generated/titleDefinitions.json'
import activityDefinitions from '../generated/activityDefinitions.json'
import seasonDefinitions from '../generated/seasonDefinitions.json'
import '@splidejs/vue-splide/css'
import { parseHtmlEntities } from '../utils'

const route = useRoute()
const router = useRouter()
const isNotMobile = useMediaQuery('(min-width: 640px)')
const accounts = ref([] as Account[])
const accountRanks = ref({} as Record<string, number>)
const activities = ref({} as Record<string, Activity[]>)
const loadingActivities = ref([] as string[])
const loadedActivities = ref([] as string[])
const isOpen = ref({
  detail: false,
  seasons: false,
  activities: false,
  characters: true
})
const loading = ref(false)
const animating = ref(false)
const errors = ref([] as Error[])
const quria = new Quria({
  API_KEY: import.meta.env.VITE_BUNGIE_API_KEY
})
const logo = ref<HTMLElement>()

function removeError(index: number) {
  const spliced = errors.value
  spliced.splice(index, 1)
  errors.value = spliced
}

function toggle(tag: 'characters') {
  isOpen.value[tag] = !isOpen.value[tag]
}

async function addSearchAccount(accountResult: AccountResult) {
  if (accounts.value.find(x => x.membershipId === accountResult.membershipId)) return

  setLoading(true)

  try {
    await load(accountResult.membershipId)
  } catch (error: Error|unknown) {
    console.error(error)
    if (error instanceof Error) errors.value = [...errors.value, error]
  }

  let accountIds = accounts.value.map(x => x.membershipId)
  accountIds = [...new Set(accountIds)]
  router.replace({ name: 'Home', params: { memberships: accountIds } })
  setLoading(false)
}

async function removeAccount(account: Account) {
  if (!accounts.value.find(x => x.membershipId === account.membershipId)) return

  accounts.value = accounts.value.filter(x => x.membershipId !== account.membershipId)
  let accountIds = accounts.value.map(x => x.membershipId)
  accountIds = [...new Set(accountIds)]
  router.replace({ name: 'Home', params: { memberships: accountIds } })
}

function getTitles(records: Record<string, DestinyRecordComponent>) {
  if (!records) return []
  return titleDefinitions
    .filter(x => ((records[x.id]?.state ?? 0) & DestinyRecordState.CanEquipTitle) === DestinyRecordState.CanEquipTitle)
    .map(x => ({ ...x, gildedCount: x.gilding ? records[x.gilding]?.completedCount ?? 0 : 0 }))
}

function getLastActivity(activities: Record<string, DestinyCharacterActivitiesComponent>) {
  if (!activities) return null
  const sortedActivities = Object.values(activities)
    .sort((a, b) => Date.parse(b.dateActivityStarted) - Date.parse(a.dateActivityStarted))
    .filter(x => x.currentActivityHash > 0)
  return sortedActivities.length
    ? activityDefinitions.find(x => x.id === `${sortedActivities[0].currentActivityHash}`)?.name || 'In orbit'
    : null
}

function getSeasonInfo(progressions: Record<string, DestinyCharacterProgressionComponent>): Season[] {
  const firstCharacterProgressions = progressions ? Object.values(progressions)[0] : null

  return seasonDefinitions.map(x => {
    const progression = firstCharacterProgressions?.progressions?.[x.rewardHash]
    const seasonRank = (progression?.currentProgress ?? 0) > 1000
      ? (progression?.level || 1) + (firstCharacterProgressions?.progressions?.[x.prestigeHash]?.level || 0)
      : 0
    return {
      season: x.name,
      number: x.number,
      startDate: new Date(x.startDate),
      seasonRank: seasonRank || '-',
      seasonPrestige: firstCharacterProgressions?.progressions?.[x.prestigeHash]?.level || 0
    }
  })
}

function getLegacySeason(progressions: Record<string, DestinyCharacterProgressionComponent>): Season {
  const firstCharacterProgressions = progressions ? Object.values(progressions)[0] : null
  const progression = firstCharacterProgressions?.progressions?.[2030054750]
  const seasonRank = progression?.level || 0
  return {
    season: 'Legacy',
    number: 1,
    seasonRank: '-',
    seasonPrestige: seasonRank
  }
}

async function load(membershipId: string) {
  if (accounts.value.find(x => x.membershipId === membershipId)) return

  fetch(`${import.meta.env.VITE_API_URL}/profile/${membershipId}`)
    .then(async x => await x.json())
    .then((data: LeaderboardClanPlayer & { rank: number }) => {
      accountRanks.value[data.membershipId] = data.rank
    })
    .catch(() => {})

  const linkedProfiles = await quria.destiny2.GetLinkedProfiles(membershipId, BungieMembershipType.All, { getAllMemberships: true })
  let userInfo = linkedProfiles.Response.profiles.find(x => x.membershipId === membershipId)

  if (!userInfo) {
    userInfo = linkedProfiles.Response.profilesWithErrors.find(x => x.infoCard.membershipId === membershipId)?.infoCard as DestinyProfileUserInfoCard|undefined
    if (!userInfo) throw new Error('Unable to find this account.')
    
    const d1Stats = await loadD1Stats(userInfo.membershipType, userInfo.membershipId)
    const d1TotalTime = d1Stats[1].Response?.characters
      ?.reduce((a: number, x: any) => a + parseInt(x?.merged?.allTime?.secondsPlayed?.basic?.value), 0) ?? 0
    const d1DeletedTime = d1Stats[1].Response?.characters
      ?.filter((x: any) => x.deleted)
      ?.reduce((a: number, x: any) => a + parseInt(x?.merged?.allTime?.secondsPlayed?.basic?.value), 0) ?? 0
    const d1Characters = d1Stats[1].Response?.characters?.map((x: any) => {
      const character = d1Stats[0].Response?.data?.characters?.find((y: any) => y.characterBase.characterId === x.characterId)
      return {
        characterId: `legacy_${x.characterId}`,
        deleted: x.deleted,
        lastPlayed: new Date(character?.characterBase?.dateLastPlayed),
        emblem: 'https://bungie.net/' + character?.emblemPath + '|https://bungie.net/' + character?.backgroundPath,
        level: character?.characterBase?.powerLevel,
        race: character?.characterBase?.raceHash,
        gender: character?.characterBase?.genderHash,
        class: character?.characterBase?.classHash,
        timePlayed: x.merged?.allTime?.['secondsPlayed']?.basic?.value || 0
      }
    })
    
    const legacyAccountData: Account = {
      displayName: userInfo.displayName,
      membershipId: userInfo.membershipId,
      membershipType: userInfo.membershipType,
      crossSaveOverride: 99999,
      d1TotalTime,
      d1DeletedTime,
      characters: d1Characters,
      fireteam: [],
      private: false
    }
    accounts.value = [...accounts.value.filter(x => x.membershipId !== legacyAccountData.membershipId), legacyAccountData]
    return
  }

  const profile = await quria.destiny2.GetProfile(userInfo.membershipId, userInfo.membershipType, {
    components: [ DestinyComponentType.Profiles, DestinyComponentType.Characters, DestinyComponentType.CharacterProgressions,
      DestinyComponentType.CharacterActivities, DestinyComponentType.Records, DestinyComponentType.Transitory ]
  })
  if (!profile.Response?.profile?.data) throw new Error('Unable to find this account.')
  const characterStats = await quria.destiny2.GetHistoricalStatsForAccount(userInfo.membershipId, userInfo.membershipType, {})
  const clan = await quria.groupv2.GetGroupsForMember(GroupsForMemberFilter.All, GroupType.Clan, userInfo.membershipId, userInfo.membershipType)

  let d1TotalTime = 0
  let d1DeletedTime = 0
  let d1Characters: Character[] = []

  if (userInfo.membershipType === BungieMembershipType.TigerPsn || userInfo.membershipType === BungieMembershipType.TigerXbox) {
    try {
      const d1Stats = await loadD1Stats(userInfo.membershipType, userInfo.membershipId)
      d1TotalTime = d1Stats[1].Response?.characters
        ?.reduce((a: number, x: any) => a + parseInt(x?.merged?.allTime?.secondsPlayed?.basic?.value), 0) ?? 0
      d1DeletedTime = d1Stats[1].Response?.characters
        ?.filter((x: any) => x.deleted)
        ?.reduce((a: number, x: any) => a + parseInt(x?.merged?.allTime?.secondsPlayed?.basic?.value), 0) ?? 0
      d1Characters = d1Stats[1].Response?.characters?.map((x: any) => {
        const character = d1Stats[0].Response?.data?.characters?.find((y: any) => y.characterBase.characterId === x.characterId)
        return {
          characterId: `legacy_${x.characterId}`,
          deleted: x.deleted,
          lastPlayed: new Date(character?.characterBase?.dateLastPlayed),
          emblem: 'https://bungie.net/' + character?.emblemPath + '|https://bungie.net/' + character?.backgroundPath,
          level: character?.characterBase?.powerLevel,
          race: character?.characterBase?.raceHash,
          gender: character?.characterBase?.genderHash,
          class: character?.characterBase?.classHash,
          timePlayed: x.merged?.allTime?.['secondsPlayed']?.basic?.value || 0
        }
      }) || []
    } catch (_) {}
  }

  const titles = getTitles(profile.Response.profileRecords?.data?.records) || []
  const activity = getLastActivity(profile.Response.characterActivities?.data)
  const characters = characterStats.Response?.characters?.map(x => {
    const character = profile.Response?.characters?.data?.[x.characterId]
    return {
      characterId: x.characterId,
      deleted: x.deleted,
      lastPlayed: new Date(character?.dateLastPlayed),
      emblem: character?.emblemHash,
      level: character?.light,
      race: character?.raceHash,
      gender: character?.genderHash,
      class: character?.classHash,
      timePlayed: x.merged?.allTime?.['secondsPlayed']?.basic?.value || 0,
      timeAfk: (parseInt(character?.minutesPlayedTotal) * 60) - x.merged?.allTime?.['secondsPlayed']?.basic?.value || 0,
      title: character?.titleRecordHash ?? undefined
    }
  }) || []
  const account: Account = {
    membershipId: userInfo.membershipId,
    membershipType: userInfo.membershipType,
    crossSaveOverride: userInfo.crossSaveOverride,
    displayName: formatUsername(userInfo.bungieGlobalDisplayName, userInfo.bungieGlobalDisplayNameCode || 0, userInfo.displayName),
    guardianRank: {
      previous: profile.Response.profile.data.currentGuardianRank,
      current: profile.Response.profile.data.renewedGuardianRank || 1,
      highest: profile.Response.profile.data.lifetimeHighestGuardianRank || 1,
    },
    clan: clan.ErrorCode === 1 && clan.Response.results?.[0]?.group ? {
      name: parseHtmlEntities(clan.Response.results[0].group.name),
      id: clan.Response.results[0].group.groupId
    } : undefined,
    d1TotalTime,
    d1DeletedTime,
    activeTriumph: profile.Response.profileRecords?.data?.activeScore ?? 0,
    totalTriumph: profile.Response.profileRecords?.data?.lifetimeScore ?? 0,
    titles: titles,
    beforeSeasons: getLegacySeason(profile.Response.characterProgressions?.data),
    seasons: getSeasonInfo(profile.Response.characterProgressions?.data),
    lastActivity: activity,
    dateLastPlayed: new Date(profile.Response.profile.data.dateLastPlayed),
    characters: [...characters, ...d1Characters],
    fireteam: profile.Response.profileTransitoryData?.data?.partyMembers?.map(x => x.membershipId) ?? [],
    private: !profile.Response.profileRecords?.data
  }
      
  if (account.private) {
    // Short-circuit for privacy
    throw new Error('This account is private. You can change your privacy settings on <a target="_blank" href="https://www.bungie.net/7/en/User/Account/Privacy">Bungie.net</a>.', {
      cause: 'privacy'
    })
  }

  accounts.value = [...accounts.value.filter(x => x.membershipId !== account.membershipId), account]

  loadActivities(accounts.value)
}

async function loadD1Stats(membershipType: BungieMembershipType, membershipId: string) {
  const accountRequest = await fetch(`https://www.bungie.net/d1/Platform/Destiny/${membershipType}/Account/${membershipId}/`, {
		headers: {
			'X-API-Key': import.meta.env.VITE_BUNGIE_API_KEY
		}
	})
  const accountResponse = await accountRequest.json()
  const statsRequest = await fetch(`https://www.bungie.net/d1/Platform/Destiny/Stats/Account/${membershipType}/${membershipId}/`, {
		headers: {
			'X-API-Key': import.meta.env.VITE_BUNGIE_API_KEY
		}
	})
  const statsResponse = await statsRequest.json()
  return [accountResponse, statsResponse]
}

async function* loadCharacterActivities(account: Account, character: Character) {
  let offset = 0

  while (true) {
    const promises: Promise<APIResponse<DestinyActivityHistoryResults>>[] = []
    
    for (let i = 0; i < 4; i++) {
      promises.push(quria.destiny2.GetActivityHistory(character.characterId, account.membershipId, account.membershipType!, {
        count: 100,
        page: offset++
      }))
    }

    const responses = await Promise.all(promises)

    for (const response of responses) {
      if (!response.Response?.activities?.length) return
      
      for (const activity of response.Response?.activities)
      {
        yield {
          id: activity.activityDetails.instanceId,
          modes: activity.activityDetails.modes.length ? activity.activityDetails.modes : [-1],
          period: new Date(activity.period),
          timePlayed: activity.values?.['timePlayedSeconds']?.basic?.value
        }
      }
    }
  }
}

async function loadActivities(accounts: Account[]) {
  for (const account of accounts) {
    if (loadedActivities.value.includes(account.membershipId) || loadingActivities.value.includes(account.membershipId)) continue

    loadingActivities.value.push(account.membershipId)
    activities.value[account.membershipId] = []
    const promises: Promise<void>[] = []

    for (const character of account.characters || []) {
      if (!character.characterId.startsWith('legacy')) {
        promises.push((async () => {
          for await (const activity of loadCharacterActivities(account, character)) {
            activities.value[account.membershipId].push(activity)
          }
        })())
      }
    }
    
    await Promise.allSettled(promises)
    loadedActivities.value.push(account.membershipId)
    loadingActivities.value.splice(loadingActivities.value.indexOf(account.membershipId), 1)
  }
}

async function loadFromUrl() {
  if (!route.params.memberships?.length) return

  scrollTo({
    top: 0,
    behavior: 'smooth'
  })
  setLoading(true)
  const accountPromises: Promise<void>[] = []

  for (const membershipId of (route.params.memberships as string[])) {
    accountPromises.push((async () => {
      try {
        await load(membershipId)
      } catch (error: Error|unknown) {
        console.error(error)
        if (error instanceof Error) errors.value = [...errors.value, error]
      }
    })())
  }

  await Promise.allSettled(accountPromises)
  let accountIds = accounts.value.map(x => x.membershipId)
  accountIds = [...new Set(accountIds)]
  router.replace({ name: 'Home', params: { memberships: accountIds } })
  setLoading(false)
}

watch(() => route.params, () => {
  if (route.params.memberships?.length && (route.params.memberships as string[]).some(x => !accounts.value.some(y => y.membershipId === x))) {
    loadFromUrl()
  }
})

const creatorLink = computed(() => ({
  name: 'Home',
  params: {
    memberships: (route.params.memberships as string[] || []).filter(x => x !== '4611686018467210709').concat([ '4611686018467210709' ])
  }
}))

onMounted(async () => {
  preventMutations()
  await loadFromUrl()
})

function preventMutations() {
  const observer = new MutationObserver((mutationList) => {
    for (const mutation of mutationList) {
      if (mutation.type === 'attributes' && mutation.attributeName === 'style') {
        if (mutation.target instanceof HTMLElement && mutation.target.getAttribute('style')?.includes('important')) {
          mutation.target.removeAttribute('style')
        }
      }
    }
  })

  observer.observe(logo.value!.getRootNode(), {
    attributes: true,
    subtree: true
  })
}

function setLoading(value: boolean) {
  loading.value = value
  setTimeout(() => {
    animating.value = value
  }, 500)
}

async function loadFireteam(members: string[]) {
  setLoading(true)
  const accountPromises: Promise<void>[] = []
  
  for (const membershipId of members) {
    accountPromises.push((async () => {
      try {
        await load(membershipId)
      } catch (error: Error|unknown) {
        console.error(error)
        if (error instanceof Error) errors.value = [...errors.value, error]
      }
    })())
  }

  await Promise.allSettled(accountPromises)
  let accountIds = accounts.value.map(x => x.membershipId)
  accountIds = [...new Set(accountIds)]
  router.replace({ name: 'Home', params: { memberships: accountIds } })
  setLoading(false)
}
</script>

<template>
  <div class="flex flex-col items-center justify-center h-fit screen">
    <div v-if="!accounts.length" class="flex-grow" />
    <div class="text-center px-1 mt-4 mb-10 text-zinc-200" ref="logo">
      <MainLogo class="max-w-full w-[30rem] sm:w-[40rem] lg:w-[50rem]" />
      <h1 class="text-4xl font-bold shadow-black/50 text-shadow-sm hidden">Time Wasted on Destiny</h1>
      <h2 class="text-lg md:text-xl font-thin shadow-black/50 text-shadow-sm hidden">Check how much time you and your friends spent on Destiny 2!</h2>
    </div>
    <div class="mb-10 w-full transition-all duration-500 shadow-lg shadow-black/50 rounded-3xl bg-white" :class="{ 'expanded-search': !loading, 'collapsed-search': loading }">
      <div class="relative">
        <div class="py-2 w-[6.5rem] h-12" />
        <div v-if="loading || animating" class="absolute top-0 right-0 w-24 h-10 p-1 m-1 rounded-3xl bg-blue-500 text-zinc-100 text-3xl leading-7 font-serif font-bold flex gap-2 justify-center">
          <span class="transition-opacity bullet-1">&bullet;</span>
          <span class="transition-opacity bullet-2">&bullet;</span>
          <span class="transition-opacity bullet-3">&bullet;</span>
        </div>
      </div>
      <SearchBar class="-mt-12" v-if="!loading && !animating" @search="addSearchAccount" />
    </div>
    <!-- <a class="font-mono opacity-90 bg-gradient-to-r from-pink-500 to-indigo-500 w-full max-w-[30rem] -mt-6 mb-4 rounded-lg no-underline hover:from-pink-400 hover:to-indigo-400 text-center shadow-lg border border-zinc-700/50" target="_blank" href="https://predict.wastedondestiny.com">
      <h3 class="text-white font-bold pt-2 text-lg">DESTINY 2: THE FINAL SHAPE PREDICTIONS</h3>
      <p class="text-sm text-white p-2">Special occasion: <i>The Final Shape</i> is almost out! Click here to try and score points by predicting what's to come!</p>
    </a> -->
    <div v-if="accounts.length" class="flex w-full mb-10">
      <Splide :options="{ pagination: false, autoWidth: true, focus: 'center', padding: 8 }" class="w-full">
        <SplideSlide v-for="account in accounts.slice(0, Math.ceil(accounts.length / 2))" :key="account.membershipId" class="px-2">
          <AccountCard :account="account" :accounts="accounts" :rank="accountRanks[account.membershipId]" :activities="activities[account.membershipId]" :activities-loaded="loadedActivities.includes(account.membershipId)" :loading="loading" :is-open="isOpen" @toggle="toggle" @remove="removeAccount" @loadFireteam="loadFireteam" />
        </SplideSlide>
        <SplideSlide v-if="isNotMobile && accounts.length % 2 === 0" />
        <SplideSlide v-for="account in accounts.slice(Math.ceil(accounts.length / 2))" :key="account.membershipId" class="px-2">
          <AccountCard :account="account" :accounts="accounts" :rank="accountRanks[account.membershipId]" :activities="activities[account.membershipId]" :activities-loaded="loadedActivities.includes(account.membershipId)" :loading="loading" :is-open="isOpen" @toggle="toggle" @remove="removeAccount" @loadFireteam="loadFireteam" />
        </SplideSlide>
      </Splide>
    </div>
    <div v-else class="flex-grow" />
    <TheFooter name="Time Wasted on Destiny">
      <RouterLink class="!decoration-1 !decoration-dotted" :to="creatorLink">&copy; François (binarmorker) Allard {{ (new Date()).getFullYear() }}</RouterLink>
    </TheFooter>
    <div class="fixed bottom-0 flex flex-col-reverse gap-2 mb-4" style="z-index: 9999;">
      <ErrorToast v-for="(error, i) in errors" :key="i" @close="() => removeError(i)">
        <span v-if="error.cause === 'privacy'" v-html="error.message" />
        <span v-else>{{ error?.message || error }}</span>
      </ErrorToast>
    </div>
  </div>
</template>

<style lang="postcss">
html {
  scroll-behavior: smooth;
}

.screen {
  min-height: calc(100dvh - 8rem);
}

.expanded-search {
  max-width: 18rem;
}

.collapsed-search {
  max-width: 6.5rem;
}

@media (min-width: 768px) {
  .expanded-search {
    max-width: 24rem;
  }
}

.bullet-1 {
  opacity: 0;
  animation: dot 1.3s infinite;
  animation-delay: 0.1s;
}

.bullet-2 {
  opacity: 0;
  animation: dot 1.3s infinite;
  animation-delay: 0.2s;
}

.bullet-3 {
  opacity: 0;
  animation: dot 1.3s infinite;
  animation-delay: 0.3s;
}

@keyframes dot {
  0% { opacity: 0; }
 20% { opacity: 1; }
100% { opacity: 0; }
}

.reduced-motion .bullet-1, .reduced-motion .bullet-2, .reduced-motion .bullet-3 {
  animation-play-state: paused;
  opacity: 1;
}

@media (prefers-reduced-motion) {
  .bullet-1, .bullet-2, .bullet-3 {
    animation-play-state: paused;
    opacity: 1;
  }
}
</style>
